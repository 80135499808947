import { action, observable, computed } from "mobx";
import RootStore from "./rootStore";
import axios from "axios";

class AuthStore
{
    private rootStore: RootStore;

    @observable public isAuthenticated: boolean;
    @observable public inProcess: boolean;
    @observable public error: string | null = null;

    public constructor(root: RootStore, state: object) {
        this.rootStore = root;
        // @ts-ignore
        this.isAuthenticated = state.isAuthenticated || false;
    }

    @action public login(email: string, password: string): PromiseLike<any> {
        this.inProcess = true;
        this.error = null;

        return axios.post<any>("/api/v1/auth/login", { email, password }).then(() => {
            this.inProcess = false;
            this.setAuthenticated(true);
        }).catch((err) => {
            this.inProcess = false;
            this.error = err.response.data.message;
            this.setAuthenticated(false);
        });
    }

    @action public logout(): PromiseLike<any> {
        return axios.post<any>("/api/v1/auth/logout").then(() => {
            this.setAuthenticated(false);
        });
    }

    @action public setAuthenticated(isAuthenticated: boolean): void {
        this.isAuthenticated = isAuthenticated;
    }
}

export default AuthStore;