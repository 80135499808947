/* global window */

import WidgetSettingsStore from "./stores/widgetSettingsStore";

if (process.env.NODE_ENV==='development') {
    require("preact/debug");
}

import { h, render } from 'preact';
import { App } from './app';
import RootStore from './stores/rootStore';
import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import { StoreProvider, WidgetSettingsStoreProvider } from "./helpers/store-provider";
import { ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';
import { SyncTrunk } from 'mobx-sync/lib/sync';


import './statistics.scss';
import "antd/dist/antd.css";

moment.locale("ru")

// @ts-ignore
let store = new RootStore(window.__STATE__);

let settingsStore = new WidgetSettingsStore();
let trunk = new SyncTrunk(settingsStore, { storage: localStorage })
trunk.init();

axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
        // todo: fix me
        config.headers['Content-Type'] = `application/json`;
        config.headers['common']['Content-Type'] = `application/json`;
        config.headers['common']['Accept'] = 'application/json';
        return config;
    }, 
    (error: any) => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    }, 
    (error: AxiosError) => {
        if (error.response.status === 403) {
            store.authStore.setAuthenticated(false);
        } 
        return Promise.reject(error);
    }
);

const container = window.document.getElementById('app');
container.innerHTML = '';

render(
    <ConfigProvider>
        <WidgetSettingsStoreProvider value={settingsStore}>
          <StoreProvider value={store}>
            <App />
          </StoreProvider>
        </WidgetSettingsStoreProvider>
    </ConfigProvider>,
    container
);