import { h, FunctionalComponent } from 'preact';
import {
    BrowserRouter as Router,
    Route,
    Switch
} from 'react-router-dom';

import { Login } from './views/login';
import { Settings } from './views/settings';
import { NotFound } from './views/notFound';
import { PrivateRoute } from './components/privateRoute';

export const App: FunctionalComponent = () => {
    return (
        <div className="container">
            <Router>
                <Switch>
                    <Route path="/settings" exact><Settings/></Route>
                    <Route path="*"><NotFound/></Route>
                </Switch>
            </Router>
        </div>
    )
}