import { action, observable, computed } from "mobx";
import axios from "axios";
import RootStore from "./rootStore";
import moment from "moment";

class Operator {
    user_id: string
    last_event_at: string
}

class HistoryItem {
    public date: string
    public offline: number
    public online: number
    public call_await_answers: number
    public call_durations: number
}

class HistoryData {
    user_id: string
    items: HistoryItem[]
}

class StatisticsStore
{
    private rootStore: RootStore;

    @observable public selectedOperators: string[] = [];
    @observable public search: string | null = null;

    @observable public period: moment[] = [moment().subtract(7, 'days'), moment()];

    @observable public operators: Operator[];
    @observable public operatorsInProcess: boolean;
    @observable public operatorsError: string | null;

    @observable public statistics: HistoryData[];
    @observable public statisticsInProcess: boolean;
    @observable public statisticsError: string | null;

    @observable public error: string | null;

    public constructor(root: RootStore, state: object) {
        this.rootStore = root;
    }

    @computed get filteredOperators(): Operator[] {
        if (!this.search || this.search.trim() === '') {
            return this.operators;
        }
        return this.operators.filter(operator => {
            return operator.user_id.indexOf(this.search) > -1
        })
    }

    @action public fetchOperators() {
        this.operatorsInProcess = true;
        this.operatorsError = null;
        return axios.get<Operator[]>("/api/v1/statistics/accounts").then(resp => {
            this.operatorsInProcess = false;
            this.operators = resp.data;
        }).catch((err) => {
            this.operatorsInProcess = false;
            this.operatorsError = 'К сожалению, произошла ошибка получения операторов'
        });
    }

    @action public fetchStatistics() {
        this.statisticsInProcess = true;
        this.statisticsError = null;

        let data = {
            from: this.period[0].format('YYYY-MM-DD'),
            to: this.period[1].format('YYYY-MM-DD'),
            users: this.selectedOperators
        }

        return axios.post<HistoryData[]>("/api/v1/statistics/calc", data).then(resp => {
            this.statisticsInProcess = false;
            this.statistics = resp.data;
        }).catch((err) => {
            this.statisticsInProcess = false;
            this.statisticsError = 'К сожалению, произошла ошибка получения статистики'
        });
    }
}

export default StatisticsStore;