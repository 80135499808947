import { action, observable } from "mobx";

class WidgetSettingsStore {
    @observable public echoCancellation = true;
    @observable public autoGainControl = true;
    @observable public microphoneVolume = 100; // 100%
    @observable public inputDeviceId = null;
    @observable public outputDeviceId = null;

    @action public setEchoCancellation(value: boolean) {
        this.echoCancellation = value;
    }

    @action public setAutoGainControl(value: boolean) {
        this.autoGainControl = value;
    }

    @action public setMicrophoneVolume(value: number) {
        this.microphoneVolume = value;
    }

    @action public setInputDeviceId(value: string | null) {
        this.inputDeviceId = value;
    }

    @action public setOutputDeviceId(value: string | null) {
        this.outputDeviceId = value;
    }
}

export default WidgetSettingsStore;