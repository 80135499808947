import {FunctionalComponent, h} from 'preact';
import { Form, PageHeader, Layout, Switch, Select, Alert, Progress, Row, Col } from 'antd';
import { useWidgetSettingsStore } from "../helpers/use-store";
import { useObserver } from 'mobx-react-lite';
import {useState} from "react";
import {useEffect, useRef} from "preact/hooks";
const { Content } = Layout;
const { Option } = Select;

export const Settings: FunctionalComponent = () => {
  let store = useWidgetSettingsStore();

  let [error, setError] = useState(null);
  let [soundMeterIntervalId, setSoundMeterIntervalId] = useState(null);
  let [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  let [stream, setStream] = useState<MediaStream>(null);
  let [soundMeterValues, setSoundMeterValues] = useState({ instant: 0, slow: 0 })
  let audioElement = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    fetchDevices().then(start).catch(handleError)
  }, [])

  try {
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
  } catch (e) {
    setError('Web Audio API not supported.');
  }

  // Attach audio output device to audio element using device/sink ID.
  const attachSinkId = (sinkId: string) => {
    if (typeof audioElement.current.sinkId !== 'undefined') {
      audioElement.current.setSinkId(sinkId)
        .then(() => {
          console.log(`Success, audio output device attached: ${sinkId}`);
        })
        .catch(error => {
          let errorMessage = error;
          if (error.name === 'SecurityError') {
            setError(`You need to use HTTPS for selecting audio output device: ${error}`);
            errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
          }
          setError(errorMessage);
          console.error(errorMessage);
        });
    } else {
      console.error('Browser does not support output device selection.');
    }
  }

  const fetchDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    setDevices(devices);
  }

  const gotStream = async (stream: MediaStream) => {
    setStream(stream)

    let originalTrack = stream.getAudioTracks()[0];

    console.log('Using audio device: ' + originalTrack.label);
    stream.oninactive = function() {
      console.log('Stream ended');
    };

    let audioContext = new AudioContext();
    let source = audioContext.createMediaStreamSource(stream);
    let destination = audioContext.createMediaStreamDestination();

    let volume = audioContext.createGain();
    source.connect(volume);
    volume.connect(destination);

    volume.gain.value = 1.0;

    let filteredTrack = destination.stream.getAudioTracks()[0];
    stream.addTrack(filteredTrack);
    console.log(originalTrack, filteredTrack)
    stream.removeTrack(originalTrack);

    attachSinkId(store.outputDeviceId);

    if (soundMeterIntervalId) {
      clearInterval(soundMeterIntervalId);
    }

    /** @ts-ingnore */
    const soundMeter =new window.SoundMeter(audioContext);
    soundMeter.connectToSource(stream, function(e) {
      if (e) {
        console.error(e)
        return;
      }

      const id = setInterval(() => {
          setSoundMeterValues({
            instant: (soundMeter.instant * 1000).toFixed(2),
            slow: (soundMeter.slow * 1000).toFixed(2)
          })
      }, 400);

      setSoundMeterIntervalId(id);
    });

    audioElement.current.srcObject = stream;

    // Refresh button list in case labels have become available
    fetchDevices()
  }

  const start = async () => {
    if (stream) {
      stream.getTracks().forEach(track => {
        track.stop();
      });
    }

    if (!store.inputDeviceId) {
      const inputs = devices.filter((device) => device.kind === 'audioinput')
      if (inputs.length) {
        store.setInputDeviceId(inputs[0].deviceId);
      }
    }

    if (!store.outputDeviceId) {
      const outputs = devices.filter((device) => device.kind === 'audiooutput')
      if (outputs.length) {
        store.setOutputDeviceId(outputs[0].deviceId);
      }
    }

    const constraints = {
      audio: { deviceId: store.inputDeviceId ? { exact: store.inputDeviceId } : undefined },
      video: false
    };

    navigator.mediaDevices.getUserMedia(constraints).then(gotStream).then(fetchDevices).catch(handleError);
  }

  const onEchoCancellation = value => {
    store.setEchoCancellation(value);
  }

  const onAutoGainControl = value => {
    store.setAutoGainControl(value);
  }

  const onInputDevice = value => {
    store.setInputDeviceId(value);
    start()
  }

  const onOutputDevice = value => {
    store.setOutputDeviceId(value);
    attachSinkId(value)
  }

  const onVolume = value => {
    store.setMicrophoneVolume(value);
  }

  const handleError = error => {
    console.error(error)
    setError('navigator.MediaDevices.getUserMedia error: ' + error.message);
  }

  return useObserver(() => {
    let { echoCancellation, autoGainControl, inputDeviceId, outputDeviceId } = store

    return (
      <Layout style={{ padding: '0 24px 24px' }}>
        <PageHeader title="Settings"/>
        <Content
          style={{ padding: 24, margin: 0, minHeight: '100vh' }}
        >
          <audio ref={audioElement} controls autoPlay style={{ height: '0px' }}/>

          <Form
            layout="horizontal"
          >
            {error &&<Alert message={error} type="error" style={{ marginBottom: '24px' }}/>}

            <Form.Item label="Audio input source">
              <Select onChange={onInputDevice} defaultValue={inputDeviceId}>
                {
                  devices
                    .filter((device) => device.kind === 'audioinput')
                    .map((device) =>
                      <Option value={device.deviceId}>{device.label}</Option>
                    )
                }
              </Select>
            </Form.Item>
            <Form.Item label="Audio output destination">
              <Select onChange={onOutputDevice} defaultValue={outputDeviceId}>
                {
                  devices
                    .filter((device) => device.kind === 'audiooutput')
                    .map((device) =>
                      <Option value={device.deviceId}>{device.label}</Option>
                    )
                }
              </Select>
            </Form.Item>
            <Form.Item label="Echo cancellation" name="echoCancellation">
              <Switch checked={echoCancellation} onChange={onEchoCancellation} disabled={true}/>
            </Form.Item>
            <Form.Item label="Auto gain control">
              <Switch checked={autoGainControl} onChange={onAutoGainControl} disabled={true}/>
            </Form.Item>
            <Form.Item label="Sound meter (instant)">
                <Row>
                  <Col span={6}>
                    <Progress percent={soundMeterValues.instant} />
                  </Col>
                </Row>
            </Form.Item>
            <Form.Item label="Sound meter (slow)">
              <Row>
                <Col span={6}>
                  <Progress percent={soundMeterValues.slow} />
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Content>
      </Layout>
    )
  })
}
