import AuthStore from "./authStore";
import StatisticsStore from "./statisticsStore";

class RootStore 
{
    private _authStore: AuthStore;
    private _statisticsStore: StatisticsStore;

    public constructor(state: object) {
        this._authStore = new AuthStore(this, state);
        this._statisticsStore = new StatisticsStore(this, state);
    }

    public get authStore(): AuthStore {
        return this._authStore;
    }

    public get statisticsStore(): StatisticsStore {
        return this._statisticsStore;
    }
}

export default RootStore;